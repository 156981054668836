<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">¿SON NUESTROS PRÉSTAMOS DEMASIADO GRANDES?</h1>

        <p>
          La realidad es que preferimos hacerlos grandes por 2 razones, la primera es que así
          <span class="secondary--text">intentamos “quedarnos” con el buen cliente y no con “parte” del buen cliente</span>; la segunda es
          que nuestros costes operativos bajan si el técnico visita a menos mujeres con créditos más grandes.
        </p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/our_loans_too_big.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OurLoansTooBig",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-ourLoansTooBig.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-ourLoansTooBig.png") + ")"
      });
    }
  }
};
</script>
